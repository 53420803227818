import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  AssignWorkOrderModalProps,
  AssignWorkOrderModals,
} from '../interfaces/Components';
import { Franchise } from '../interfaces/WorkOrder';
import { Van } from '../interfaces/Van';
import styles from '../sass/components/CompleteWorkOrderContainer.module.scss';
import {
  GET_FRANCHISES_OF_FRANCHISE_MANAGER,
  GET_FRANCHISE_VANS,
} from '../util/gql';
import AssignWorkOrder from './AssignWorkOrder';

export default function AssignWorkOrderContainer({
  onClose,
  franchiseId,
  vanId,
  isConfirmed,
  onSubmit,
}: AssignWorkOrderModalProps) {
  const [assignedFranchise,
    setAssignedFranchise] = useState<number>(franchiseId || 0);
  const [franchises, setFranchises] = useState<Franchise[]>([]);
  const [vans, setVans] = useState<Van[]>([]);
  const [assignedVan, setAssignedVan] = useState<number | null>(vanId || null);
  const [
    activeModal,
    setActiveModal,
  ] = useState<AssignWorkOrderModals>('assign');

  useQuery(GET_FRANCHISES_OF_FRANCHISE_MANAGER, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getFranchisesOfFranchiseManager) {
        setFranchises(data.getFranchisesOfFranchiseManager);
      }
    },
  });

  const { refetch: fetchVans } = useQuery(GET_FRANCHISE_VANS, {
    variables: { franchiseId: assignedFranchise },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getFranchiseVans) {
        setVans(data.getFranchiseVans);
      }
    },
  });

  useEffect(() => {
    if (assignedFranchise) {
      fetchVans({ franchiseId: assignedFranchise }).then(() => {
        setAssignedVan(null);
      });
    }
  }, [assignedFranchise, fetchVans]);

  const handleFranchiseChange = (newFranchiseId: number) => {
    setAssignedFranchise(newFranchiseId);
    setVans([]);
  };

  const handleSubmit = () => {
    onSubmit(
      assignedFranchise,
      assignedVan,
    );
    onClose();
  };

  return (
    <div className={styles.container}>
      {activeModal === 'assign' && (
      <AssignWorkOrder
        vans={vans}
        vanId={vanId}
        assignedVan={assignedVan}
        setAssignedVan={setAssignedVan}
        franchiseId={franchiseId}
        franchises={franchises}
        assignedFranchise={assignedFranchise}
        setAssignedFranchise={handleFranchiseChange}
        isConfirmed={isConfirmed}
        onClose={onClose}
        onClick={handleSubmit}
      />
      )}
    </div>
  );
}
