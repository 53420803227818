import cx from 'classnames';
import styles from '../sass/components/InformationSection.module.scss';

interface TabItem {
  id: string | number;
  label: string;
  isActive: boolean;
  onClick: () => void;
}

interface TabMenuProps {
  tabs: TabItem[];
}

export default function TabMenu({ tabs }: TabMenuProps) {
  return (
    <div className={styles.tabs}>
      {tabs.map((tab) => (
        <button
          key={tab.id}
          type="button"
          className={cx(styles.tab, {
            [styles.tabSelected]: tab.isActive,
          })}
          onClick={tab.onClick}
        >
          <h6 className={styles.desktopTab}>{tab.label}</h6>
          <h6 className={styles.mobileTab}>
            {tab.label}
          </h6>
        </button>
      ))}
    </div>
  );
}
