import cx from 'classnames';
import { useState } from 'react';
import { ReactComponent as Arrow } from '../images/chevron_down_small.svg';
import { AssignWorkOrderToVanProps } from '../interfaces/Components';
import styles from '../sass/components/AssignWorkOrderToVan.module.scss';
import Badge from './Badge';
import Button from './Button';
import ModalCloseButton from './ModalCloseButton';

export default function AssignWorkOrderToVan({
  onClose,
  vans,
  assignedVan,
  setAssignedVan,
  scheduledAt,
  onClick,
}: AssignWorkOrderToVanProps) {
  const [expandedVan, setExpandedVan] = useState<number | null>(null);

  const handleSelectVan = (vanId: number) => {
    setAssignedVan(vanId);
  };

  const handleRemoveVan = () => {
    setAssignedVan(null);
  };

  const toggleExpandVan = (vanId: number) => {
    setExpandedVan(expandedVan === vanId ? null : vanId);
  };

  return (
    <div className={styles.modal}>
      <ModalCloseButton onClose={onClose} />
      <div className={styles.body}>
        <h4>Assign Work Order</h4>
        <span>
          Compare vans below and select the best option for this work order
        </span>
        {`${scheduledAt}:`}
        <div className={styles.vanList}>
          {vans.map((van) => (
            <div key={van.vanId} className={styles.vanCardContainer}>
              <div
                className={cx(styles.vanCard, {
                  [styles.selectedRow]: assignedVan === van.vanId,
                })}
              >
                <Button
                  className={styles.expandButton}
                  variant="icon"
                  onClick={() => toggleExpandVan(van.vanId)}
                >
                  <Arrow
                    className={cx(styles.arrowIcon, {
                      [styles.expandedArrow]: expandedVan === van.vanId,
                    })}
                  />
                </Button>
                <div className={styles.vanCardHeader}>
                  <div className={styles.vanName}>
                    <span>{van.vanName}</span>
                    {assignedVan === van.vanId && (
                      <span className={styles.selectedLabel}>Selected</span>
                    )}
                    {van.conflicts.length > 0 && (
                      <div className={styles.conflicts}>
                        {van.conflicts.map((conflict) => (
                          <Badge
                            variant="secondary"
                            className={styles.conflictBadge}
                            key={conflict}
                          >
                            {conflict}
                          </Badge>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className={styles.actionButtons}>
                    {assignedVan === van.vanId && (
                      <Button
                        onClick={handleRemoveVan}
                        variant="tertiary"
                        error
                      >
                        Remove
                      </Button>
                    )}
                    <Button
                      className={styles.selectButton}
                      onClick={() => handleSelectVan(van.vanId)}
                      variant="primary"
                      inactive={assignedVan === van.vanId}
                    >
                      Select
                    </Button>
                  </div>
                </div>
              </div>

              {expandedVan === van.vanId && (
                <div className={styles.expandedInfoContainer}>
                  <p>
                    <strong>Score:</strong>
                    {' '}
                    {van.conflicts.length === 0 ? van.score : 'N/A'}
                  </p>
                  <p>
                    <strong>Appointments Count:</strong>
                    {' '}
                    {van.appointmentCount}
                  </p>
                  <p>
                    <strong>Driving Time From Previous:</strong>
                    {' '}
                    {van.drivingTimeFromPrevious}
                    {' '}
                    mins
                  </p>
                  <p>
                    <strong>Driving Time To Next:</strong>
                    {' '}
                    {van.drivingTimeToNext}
                    {' '}
                    mins
                  </p>
                  <p>
                    <strong>Previous Appointment Slot:</strong>
                    {' '}
                    {van.previousAppointmentSlot || 'None'}
                  </p>
                  <p>
                    <strong>Next Appointment Slot:</strong>
                    {' '}
                    {van.nextAppointmentSlot || 'None'}
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.cancelButton}
            onClick={onClose}
            variant="tertiary"
          >
            Cancel
          </Button>
          <Button
            className={styles.confirmButton}
            onClick={onClick}
            variant="primary"
            inactive={!assignedVan}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
}
