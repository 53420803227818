import { useEffect } from 'react';
import { AssignWorkOrderProps } from '../interfaces/Components';
import styles from '../sass/components/ErrorModal.module.scss';
import Button from './Button';
import ModalCloseButton from './ModalCloseButton';
import Select from './Select';

export default function AssignWorkOrder({
  onClose,
  franchises,
  franchiseId,
  assignedFranchise,
  setAssignedFranchise,
  vans,
  vanId,
  assignedVan,
  setAssignedVan,
  isConfirmed,
  onClick,
}: AssignWorkOrderProps) {
  const findFranchiseId = (id: number) => {
    const franchise = franchises.find(
      (franchiseSelect) => franchiseSelect.id === id,
    );
    return franchise ? franchise.id.toString() : '';
  };
  const findVanId = (id: number | null) => {
    if (id === null) return '-1';
    const van = vans.find((vanSelect) => vanSelect.id === id);
    return van ? van.id.toString() : '';
  };

  const handleVanChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.currentTarget.value;
    setAssignedVan(selectedId === '-1' ? null : Number(selectedId));
  };

  const handleFranchiseChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAssignedFranchise(Number(e.currentTarget.value));
    if (assignedFranchise === franchiseId) {
      setAssignedVan(vanId || null);
    } else {
      setAssignedVan(null);
    }
  };

  useEffect(() => {
    if (vans.length && isConfirmed && assignedVan === null) {
      if (assignedFranchise === franchiseId) {
        setAssignedVan(vanId || null);
      } else {
        setAssignedVan(vans[0].id);
      }
    }
  }, [vans,
    assignedFranchise,
    isConfirmed,
    assignedVan,
    setAssignedVan,
    franchiseId, vanId]);

  return (
    <div className={styles.modal}>
      <ModalCloseButton onClose={onClose} />
      <div className={styles.body}>
        <h4>Assign Work Order</h4>
        {franchises.length > 1 && (
          <>
            <p> Select a Franchise this work order should be assigned to </p>
            <Select
              label=""
              loading={false}
              id="Franchise Select"
              placeholder="Select a Franchise"
              value={
                assignedFranchise ? findFranchiseId(assignedFranchise)
                  : ''
                }
              onChange={handleFranchiseChange}
            >
              {franchises.map((franch) => (
                <option key={franch.id} value={franch.id}>
                  {franch.id === franchiseId
                    ? `${franch.name} (currently selected)`
                    : franch.name}
                </option>
              ))}
            </Select>
          </>
        )}
        {vans.length > 1 && (
          <>
            <p> Select a Van this work order should be assigned to </p>
            <Select
              label=""
              loading={false}
              id="Van Select"
              placeholder="Select a Van"
              value={findVanId(assignedVan)}
              onChange={handleVanChange}
            >
              {!isConfirmed && <option value="-1">Not Assigned</option>}
              {vans.map((van) => (
                <option key={van.id} value={van.id}>
                  {van.id === vanId
                    ? `${van.name} (currently selected)`
                    : van.name}
                </option>
              ))}
            </Select>
          </>
        )}
        <br />
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.button}
            onClick={onClose}
            variant="tertiary"
          >
            Cancel
          </Button>
          <Button
            className={styles.button}
            onClick={onClick}
            variant="primary"
            inactive={(isConfirmed && !assignedVan)
              || (!isConfirmed && !!assignedVan)}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
}
