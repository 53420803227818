import styles from '../sass/components/WorkOrderList.module.scss';
import PayoutReportRow from './PayoutReportRow';
import { GroupedPayoutReports } from '../interfaces/PayoutReport';

interface PayoutReportListProps {
  payoutReports: GroupedPayoutReports;
}

export default function PayoutReportList({ payoutReports }:
  PayoutReportListProps) {
  return (
    <>
      {/* Loop through each month (grouped by date ranges) */}
      {Object.keys(payoutReports).map((monthYear) => (
        <div key={monthYear}>
          <div className={styles.date}>
            {monthYear}
          </div>

          {payoutReports[monthYear].map((groupedRange) => {
            const { count, reports } = groupedRange;

            return (
              <div key={`${groupedRange.startDate}-${groupedRange.endDate}`}>
                <PayoutReportRow
                  reports={reports}
                  count={count}
                />
              </div>
            );
          })}
        </div>
      ))}
    </>
  );
}
