import { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { useAppSelector } from '../app/hooks';
import { TabDictionary } from '../interfaces/TabDictionary';
import InformationSection from './InformationSection';
import WorkOrderAppointments from './WorkOrderAppointments';
import { WorkOrderStatus } from '../interfaces/WorkOrder';
import { GET_FRANCHISE_VANS, GET_TECHNICIAN_VANS } from '../util/gql';
import TabMenu from './TabMenu';

interface Van {
  id: number;
  name: string;
}
interface LocationState {
  vanIdParam?: number;
}

export default function WorkOrdersInformation() {
  const [vans, setVans] = useState<Van[]>([]);
  const location = useLocation();
  const { vanIdParam } = location.state as LocationState || {};
  const [selectedVanId, setSelectedVanId] = useState<number | null>(null);
  const [isVansLoading, setIsVansLoading] = useState(true);

  const franchiseId = useAppSelector((state) => state.franchise.id);
  const currentUser = useAppSelector((state) => state.auth.currentUser);

  const isManager = currentUser.roles.includes('manager');
  const isTechnician = currentUser.roles.includes('technician');
  const isTester = currentUser.roles.includes('tester');

  const [getVans] = useLazyQuery(GET_FRANCHISE_VANS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const franchiseVans = data?.getFranchiseVans || [];
      if (franchiseVans.length) {
        setVans(franchiseVans);
        setSelectedVanId(vanIdParam || franchiseVans[0].id);
      } else {
        setSelectedVanId(null);
      }
      setIsVansLoading(false);
    },
  });

  const [getTechnicianVans] = useLazyQuery(GET_TECHNICIAN_VANS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const technicianVans = data?.getTechnicianVans || [];
      if (technicianVans.length) {
        setVans(technicianVans);
        setSelectedVanId(technicianVans[0].id);
      } else {
        setSelectedVanId(null);
      }
      setIsVansLoading(false);
    },
  });

  useEffect(() => {
    if ((isManager || isTester) && franchiseId) {
      setIsVansLoading(true);
      getVans({ variables: { franchiseId } });
    }

    if (isTechnician) {
      setIsVansLoading(true);
      getTechnicianVans({ variables: { technicianId: currentUser.id } });
    }
  }, [getVans,
    getTechnicianVans,
    franchiseId,
    isManager,
    isTechnician,
    isTester,
    currentUser.id]);

  const handleVanSelection = (vanId: number | null) => {
    setSelectedVanId(vanId);
  };

  const vanTabs = useMemo(() => [
    ...[{
      id: 'unassigned',
      label: 'Requested Work Orders',
      isActive: selectedVanId === null,
      onClick: () => handleVanSelection(null),
    },
    ],
    ...vans.map((van) => ({
      id: van.id,
      label: van.name,
      isActive: selectedVanId === van.id,
      onClick: () => handleVanSelection(van.id),
    })),
  ], [vans, selectedVanId]);

  const commonTabs: TabDictionary = useMemo(() => ({
    requestedWorkOrders: {
      desktopValue: 'Requested Work Orders',
      mobileValue: 'Requested',
      component: (
        <WorkOrderAppointments
          workOrderStatus={WorkOrderStatus.assigned}
          emptyListMsg="No requested work orders"
          isConfirmed={false}
        />
      ),
    },
    upcomingWorkOrders: {
      desktopValue: 'Upcoming Work Orders',
      mobileValue: 'Upcoming',
      component: (
        <WorkOrderAppointments
          workOrderStatus={WorkOrderStatus.assigned}
          emptyListMsg="No upcoming work orders"
          isConfirmed
          vanId={selectedVanId}
        />
      ),
    },
    pastWorkOrders: {
      desktopValue: 'Completed Work Orders',
      mobileValue: 'Completed',
      component: (
        <WorkOrderAppointments
          workOrderStatus={WorkOrderStatus.complete}
          emptyListMsg="No completed work orders"
          vanId={selectedVanId}
        />
      ),
    },
  }), [selectedVanId]);

  const tabs = useMemo(() => {
    if ((isManager || isTester) && vans.length <= 1) {
      return commonTabs;
    } if (isTechnician && vans.length <= 1) {
      return commonTabs;
    }
    return {
      upcomingWorkOrders: commonTabs.upcomingWorkOrders,
      pastWorkOrders: commonTabs.pastWorkOrders,
    };
  }, [isManager, isTechnician, isTester, vans.length, commonTabs]);

  if (isVansLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {isTechnician && vans.length === 0 ? (
        <p>No vans available. Please ask a manager to assign a van.</p>
      ) : (
        <>
          {vans.length > 1 && <TabMenu tabs={vanTabs} />}
          {selectedVanId === null && vans.length > 1 ? (
            <WorkOrderAppointments
              workOrderStatus={WorkOrderStatus.assigned}
              emptyListMsg="No requested work orders"
              isConfirmed={false}
            />
          ) : (
            Object.keys(tabs).length > 0 && (
            <InformationSection tabs={tabs} initialState="upcomingWorkOrders" />
            )
          )}
        </>
      )}
    </div>
  );
}
