import { useMutation } from '@apollo/client';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import Delete from '@mui/icons-material/Delete';
import { useState, FormEvent } from 'react';
import { useAppSelector } from '../app/hooks';
import { Vehicle } from '../interfaces/Vehicle';
import styles from '../sass/components/TableRowView.module.scss';
import {
  FIND_ALL_USER_VEHICLES_BY_CURRENT_USER,
  UPDATE_USER_VEHICLE_VIN_WITH_ID,
  UPDATE_USER_VEHICLE_NAME_WITH_ID,
  IS_WORK_ORDER_ASSIGNED_TO_USER_VEHICLE,
} from '../util/gql';
import Button from './Button';
import ErrorModal from './ErrorModal';
import MileageModal from './MileageModal';
import TextInput from './TextInput';
import { dayTimeString } from '../util/formatDate';

export default function VehicleRowView(props: { data: Vehicle }) {
  const { data } = props;
  const currentUser = useAppSelector((
    state,
  ) => state.auth.currentUser);

  const [mileageModal, setMileageModal] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState<boolean>(false);

  const isManager = currentUser.roles?.includes('manager');
  const isTechnician = currentUser.roles?.includes('technician');
  const isTester = currentUser.roles?.includes('tester');

  const rowText = `${data.year} ${data.make} ${data.model}`;

  // eslint-disable-next-line max-len
  const [checkWorkOrder, { data: checkData }] = useMutation(IS_WORK_ORDER_ASSIGNED_TO_USER_VEHICLE);

  const [addVin] = useMutation(UPDATE_USER_VEHICLE_VIN_WITH_ID);
  const [addName] = useMutation(UPDATE_USER_VEHICLE_NAME_WITH_ID);

  const handleDelete = async () => {
    const result = await checkWorkOrder({
      variables: { id: data.userVehicleId },
      fetchPolicy: 'network-only',
      refetchQueries: [{ query: FIND_ALL_USER_VEHICLES_BY_CURRENT_USER }],
    });
    if (result.data.isUserVehicleAssignedToWorkOrder === true) {
      // eslint-disable-next-line max-len, no-alert
      alert('Could not delete vehicle because an assigned work order still exists.');
    }
  };

  const handleVin = () => {
    addVin({
      variables: { vin: data.vin, userVehicleId: data.userVehicleId },
      refetchQueries: [{ query: FIND_ALL_USER_VEHICLES_BY_CURRENT_USER }],
    });
  };
  const handleName = () => {
    addName({
      variables: { name: data.name, userVehicleId: data.userVehicleId },
      refetchQueries: [{ query: FIND_ALL_USER_VEHICLES_BY_CURRENT_USER }],
    });
  };

  const handleErrorCreatingMileage = () => {
    setMileageModal(false);
    setErrorModal(true);
  };

  const handleVinChange = (event: FormEvent<HTMLInputElement>) => {
    const newVin = event.currentTarget.value;
    data.vin = newVin;
  };

  const handleNameChange = (event: FormEvent<HTMLInputElement>) => {
    const newName = event.currentTarget.value;
    data.name = newName;
  };

  return (
    <div>
      <div className={styles.row}>
        <h5 className={styles.rowContent}>
          {rowText}
          <div className={styles.carIdentifiers}>
            {data.vin ? (
              <p className={styles.subtitle}>
                {'VIN: '}
                {data.vin}
              </p>
            ) : (
              <>
                <TextInput
                  id="add-Vin"
                  title="Unique Vehicle Identifier"
                  placeholder="Enter Unique Vehicle Identifier"
                  type="text"
                  isRequired
                  value={data.vin}
                  onChange={handleVinChange}
                />
                <div>
                  <Button
                    variant="secondary"
                    size="large"
                    onClick={handleVin}
                  >
                    Set VIN
                  </Button>
                </div>
              </>
            )}
          </div>
          <div className={styles.carIdentifiers}>
            {data.name ? (
              <p className={styles.subtitle}>
                {'Name: '}
                {data.name}
              </p>
            ) : (
              <>
                <TextInput
                  id="add-Name"
                  title="Optional Vehicle Information"
                  placeholder="Enter Name, Plate Nr. or Fleet Identifier"
                  type="text"
                  isRequired
                  value={data.name}
                  onChange={handleNameChange}
                />
                <Button
                  variant="secondary"
                  size="large"
                  onClick={handleName}
                >
                  Set Name
                </Button>
              </>
            )}
          </div>
          {data.mileage && (
          <p className={styles.subtitle}>
            {data.lastServiced && (
            <>
              {'Last Service: '}
              {dayTimeString(data.lastServiced)}
              {' '}
            </>
            )}
            {data.mileage && (
            <>
              {'Last Mileage: '}
              {data.mileage}
              {' '}
            </>
            )}
          </p>
          )}
        </h5>
        {!isManager && !isTechnician && !isTester && (
          <>
            <Button
              variant="icon"
              className={styles.actions}
              onClick={() => setMileageModal(true)}
            >
              <AvTimerIcon />
            </Button>

            <Button
              variant="icon"
              className={styles.actions}
              onClick={handleDelete}
            >
              <Delete />
            </Button>
          </>
        )}
        {mileageModal && data.userVehicleId && (
          <MileageModal
            mileageModal={mileageModal}
            onClose={() => setMileageModal(false)}
            currentMileage={data.mileage}
            userVehicleId={data.userVehicleId}
            onError={handleErrorCreatingMileage}
          />
        )}
        {errorModal && (
          <ErrorModal
            open={errorModal}
            onClose={() => setErrorModal(false)}
            title="Add vehicle mileage"
            message="
            Error: Could not add vehicle mileage, please try again later
            "
          />
        )}
      </div>
    </div>
  );
}
