import { useState, useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import TabMenu from './TabMenu';
import { GET_PAYOUT_REPORTS_BY_DATE } from '../util/gql';
import PayoutReportDetails from './PayoutReportDetails';

interface PayoutReport {
  id: number;
  franchiseId: number;
  startDate: string;
  endDate: string;
  status: string;
  van: {
    name: string;
  };
}

export default function PayoutVansReport() {
  const [payoutReports, setPayoutReports] = useState<PayoutReport[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const startDate = params.get('startDate');
  const endDate = params.get('endDate');
  const [selectedTab, setSelectedTab] = useState<string>('');

  const franchiseId = useAppSelector((state) => state.franchise.id);

  const [getPayoutReports] = useLazyQuery(GET_PAYOUT_REPORTS_BY_DATE, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const reports = data?.getPayoutStatementsByDate || [];
      setPayoutReports(reports);

      if (reports.length > 0) {
        setSelectedTab(reports[0].id.toString());
      }
      setIsLoading(false);
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (franchiseId) {
      setIsLoading(true);
      getPayoutReports({
        variables: { franchiseId, startDate, endDate },
      });
    }
  }, [franchiseId, startDate, endDate, getPayoutReports]);

  const handleTabChange = (id: number) => {
    setSelectedTab(id.toString());
  };

  const tabItems = payoutReports.map((report) => ({
    id: report.id,
    label: report.van.name,
    isActive: selectedTab === report.id.toString(),
    onClick: () => handleTabChange(report.id),
  }));

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (payoutReports.length === 0) {
    return <div>No payout reports available for the selected date range.</div>;
  }

  const selectedReport = payoutReports.find(
    (report) => report.id.toString() === selectedTab,
  );

  return (
    <div>
      <TabMenu tabs={tabItems} />
      {selectedReport && (
        <PayoutReportDetails
          idProps={selectedReport.id.toString()}
        />
      )}
    </div>
  );
}
