import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface BookingState {
  success: boolean;
  potentialFranchiseId?: number | null;
  vanId?: number | null;
}

const initialState: BookingState = {
  success: false,
  potentialFranchiseId: null,
  vanId: null,
};

export const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    setBookingSuccess: (state, action: PayloadAction<boolean>) => ({
      ...state,
      success: action.payload,
    }),
    setPotentialFranchiseId: (state, action: PayloadAction<number | null>) => ({
      ...state,
      potentialFranchiseId: action.payload,
    }),
    setVanId: (state, action: PayloadAction<number | null>) => ({
      ...state,
      vanId: action.payload,
    }),
  },
});

export const {
  setBookingSuccess,
  setPotentialFranchiseId,
  setVanId,
} = bookingSlice.actions;

export const selectBookingSucessState = (
  state: RootState,
) => state.booking.success;
export const selectPotentialFranchiseId = (
  state: RootState,
) => state.booking.potentialFranchiseId;
export const selectVanId = (
  state: RootState,
) => state.booking.vanId;

export default bookingSlice.reducer;
