import { useNavigate } from 'react-router-dom';
import cx from 'classnames';
import { ReactComponent as Arrow } from '../images/chevron_down_small.svg';
import styles from '../sass/components/PayoutReportRow.module.scss';
import Button from './Button';
import { dateString } from '../util/formatDate';
import { PayoutReport } from '../interfaces/PayoutReport';
import {
  ROUTE_FRANCHISE_MANAGER_PAYOUTS,
  ROUTE_FRANCHISE_MANAGER_VANS_PAYOUTS,
} from '../util/constants';

interface PayoutReportRowProps {
  reports: PayoutReport[];
  count: number;
}

export default function PayoutReportRow({ reports, count }:
  PayoutReportRowProps) {
  const navigate = useNavigate();

  const payoutReport = reports[0];

  const payoutName = () => {
    const adjStartDate = dateString(payoutReport.startDate);
    const adjEndDate = dateString(payoutReport.endDate);
    return `Statement from ${adjStartDate} - ${adjEndDate}`;
  };

  const handleButtonClick = () => {
    if (count > 1) {
      const formattedStartDate = encodeURIComponent(
        new Date(payoutReport.startDate).toISOString(),
      );
      const formattedEndDate = encodeURIComponent(
        new Date(payoutReport.endDate).toISOString(),
      );
      // eslint-disable-next-line max-len
      navigate(`${ROUTE_FRANCHISE_MANAGER_VANS_PAYOUTS}?startDate=${formattedStartDate}&endDate=${formattedEndDate}`);
    } else {
      navigate(`${ROUTE_FRANCHISE_MANAGER_PAYOUTS}/${payoutReport.id}`);
    }
  };

  return (
    <div className={styles.rowContainer}>
      <div className={styles.descriptionContainer}>
        <div className={styles.mainContent}>
          <h6>
            <strong>{payoutName()}</strong>
          </h6>
        </div>
        <Button
          className={cx(styles.caret)}
          type="button"
          onClick={handleButtonClick}
          variant="icon"
        >
          <Arrow />
        </Button>
      </div>
    </div>
  );
}
