import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  AssignWorkOrderToVanModalProps,
} from '../interfaces/Components';
import styles from '../sass/components/CompleteWorkOrderContainer.module.scss';
import AssignWorkOrderToVan from './AssignWorkOrderToVan';
import { RANK_AVAILABLE_VANS } from '../util/gql';

export default function AssignWorkOrderToVanContainer({
  onClose,
  vanId,
  workOrderId,
  scheduledAt,
  onSubmit,
}: AssignWorkOrderToVanModalProps) {
  const [assignedVan, setAssignedVan] = useState<number | null>(vanId || null);
  const [vans, setVans] = useState([]);
  const [loading, setLoading] = useState(true);

  const [
    rankAvailableVansQuery,
  ] = useLazyQuery(RANK_AVAILABLE_VANS);

  useEffect(() => {
    if (workOrderId) {
      rankAvailableVansQuery({
        variables: { workOrderId, gatherAllInfo: true },
        fetchPolicy: 'network-only',
        onCompleted: (response) => {
          setVans(response.rankAvailableVansForWorkOrder || []);
          setLoading(false);
        },
        onError: () => {
          setVans([]);
          setLoading(false);
        },
      });
    }
  }, [workOrderId, rankAvailableVansQuery]);

  const handleSubmit = () => {
    onSubmit(assignedVan);
    onClose();
  };

  return (
    <div className={styles.container}>
      {loading ? (
        <p>Loading available vans...</p>
      ) : (
        <AssignWorkOrderToVan
          vanId={vanId}
          vans={vans}
          assignedVan={assignedVan}
          setAssignedVan={setAssignedVan}
          scheduledAt={scheduledAt}
          onClose={onClose}
          onClick={handleSubmit}
        />
      )}
    </div>
  );
}
