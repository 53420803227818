import { Navigate, Route, Routes } from 'react-router-dom';
import AppointmentsInformation from '../components/AppointmentsInformation';
import CustomerInformationManagement
  from '../components/CustomerInformationManagement';
import NavBar from '../components/NavBar';
import UserInformation from '../components/UserInformation';
import VehicleInformation from '../components/VehicleInformation';
import WorkOrdersInformation from '../components/WorkOrdersInformation';
import {
  ROUTE_ACCOUNT_ACCOUNT_PASSWORD,
  ROUTE_ACCOUNT_APPOINTMENTS,
  ROUTE_ACCOUNT_CARS,
  ROUTE_ACCOUNT_CUSTOMER_INFORMATION,
  ROUTE_ACCOUNT_APPOINTMENTS_DETAIL,
  ROUTE_ACCOUNT_ROOT,
  ROUTE_AUTH_FORGOT_PASSWORD,
  ROUTE_AUTH_LOGIN,
  ROUTE_BOOK_ADD_ONS,
  ROUTE_BOOK_CONFIRMATION,
  ROUTE_BOOK_LOCATION,
  ROUTE_BOOK_ROOT,
  ROUTE_BOOK_SCHEDULE,
  ROUTE_BOOK_SERVICES,
  ROUTE_FRANCHISE_MANAGER,
  ROUTE_FRANCHISE_MANAGER_WORK_ORDERS,
  ROUTE_ROOT,
  ROUTE_FRANCHISE_MANAGER_WORK_ORDER_DETAIL,
  ROUTE_FRANCHISE_MANAGER_INVOICE_DETAIL,
  ROUTE_FRANCHISE_MANAGER_CUSTOMERS,
  // ROUTE_FRANCHISE_MANAGER_FRANCHISES,
  ROUTE_FRANCHISE_MANAGER_CUSTOMER_DETAIL,
  ROUTE_FRANCHISE_MANAGER_PAYOUTS,
  ROUTE_FRANCHISE_MANAGER_PAYOUT_DETAIL,
  ROUTE_FRANCHISE_MANAGER_VANS_PAYOUTS,
} from '../util/constants';
import Account from './Account';
import AddOns from './AddOns';
import Booking from './Booking';
import ForgotPassword from './ForgotPassword';
import FranchiseManagerPanel from './FranchiseManagerPanel';
import Location from './Location';
import Login from './Login';
import NotFound from './NotFound';
import RequiredCustomer from './RequiredCustomer';
import RequiredFranchiseManager from './RequiredFranchiseManager';
import RequireLocation from './RequireLocation';
import RequireLoggedIn from './RequireLoggedIn';
import RequireSuccessfulBooking from './RequireSuccessfulBooking';
import Schedule from './Schedule';
import Services from './Services';
import Success from './Success';
import WorkOrderDetails from '../components/WorkOrderDetails';
import InvoiceDetails from '../components/InvoiceDetails';
import CustomersInformation from '../components/CustomersInformation';
import FranchisesList from '../components/FranchisesList';
import FranchiseCustomerManagement
  from '../components/FranchiseCustomerManagement';
import CustomerWorkOrderDetails
  from '../components/CustomerWorkOrderDetails';
import PayoutReports from '../components/PayoutReports';
import PayoutReportDetails from '../components/PayoutReportDetails';
import PayoutVansReports from '../components/PayoutVansReports';

export default function Router() {
  return (
    <>
      <NavBar />
      <Routes>
        <Route
          path={ROUTE_ROOT}
          element={(
            <Navigate
              to={ROUTE_BOOK_LOCATION}
            />
          )}
        />
        <Route
          path={ROUTE_AUTH_LOGIN}
          element={<Login />}
        />
        <Route
          path={ROUTE_AUTH_FORGOT_PASSWORD}
          element={<ForgotPassword />}
        />
        <Route
          path={ROUTE_ACCOUNT_ROOT}
          element={(
            <RequireLoggedIn>
              <Account />
            </RequireLoggedIn>
          )}
        >
          <Route
            path={ROUTE_ACCOUNT_ROOT}
            element={(
              <RequiredCustomer>
                <Navigate
                  to={ROUTE_ACCOUNT_APPOINTMENTS}
                />
              </RequiredCustomer>
            )}
          />
          <Route
            path={ROUTE_ACCOUNT_APPOINTMENTS}
            element={(
              <RequiredCustomer>
                <AppointmentsInformation />
              </RequiredCustomer>
            )}
          />
          <Route
            path={ROUTE_ACCOUNT_APPOINTMENTS_DETAIL}
            element={(
              <RequiredCustomer>
                <CustomerWorkOrderDetails />
              </RequiredCustomer>
            )}
          />
          <Route
            path={ROUTE_ACCOUNT_CARS}
            element={(
              <RequiredCustomer>
                <VehicleInformation />
              </RequiredCustomer>
            )}
          />
          <Route
            path={ROUTE_ACCOUNT_CUSTOMER_INFORMATION}
            element={(
              <RequiredCustomer>
                <CustomerInformationManagement />
              </RequiredCustomer>
            )}
          />
          <Route
            path={ROUTE_ACCOUNT_ACCOUNT_PASSWORD}
            element={(
              <RequiredCustomer>
                <UserInformation />
              </RequiredCustomer>
            )}
          />
        </Route>
        <Route
          path={ROUTE_BOOK_ROOT}
          element={<Booking />}
        >
          <Route
            path={ROUTE_BOOK_LOCATION}
            element={<Location />}
          />
          <Route
            path={ROUTE_BOOK_SERVICES}
            element={(
              <RequireLocation>
                <Services />
              </RequireLocation>
            )}
          />
          <Route
            path={ROUTE_BOOK_ADD_ONS}
            element={(
              <RequireLocation>
                <AddOns />
              </RequireLocation>
            )}
          />
          <Route
            path={ROUTE_BOOK_SCHEDULE}
            element={(
              <RequireLocation>
                <Schedule />
              </RequireLocation>
            )}
          />
        </Route>
        <Route
          path={ROUTE_BOOK_CONFIRMATION}
          element={(
            <RequireSuccessfulBooking>
              <Success />
            </RequireSuccessfulBooking>
          )}
        />
        <Route
          path={ROUTE_FRANCHISE_MANAGER}
          element={(
            <RequireLoggedIn>
              <RequiredFranchiseManager>
                <FranchiseManagerPanel />
              </RequiredFranchiseManager>
            </RequireLoggedIn>
          )}
        >
          <Route
            path={ROUTE_FRANCHISE_MANAGER}
            element={(
              <RequiredFranchiseManager>
                <Navigate
                  to={ROUTE_FRANCHISE_MANAGER_WORK_ORDERS}
                />
              </RequiredFranchiseManager>
            )}
          />
          <Route
            path={ROUTE_FRANCHISE_MANAGER_WORK_ORDERS}
            element={(
              <RequiredFranchiseManager>
                <WorkOrdersInformation />
              </RequiredFranchiseManager>
            )}
          />
          {/* <Route
            path={ROUTE_FRANCHISE_MANAGER_PAYOUTS}
            element={(
              <RequiredFranchiseManager>
                <PayoutReports />
              </RequiredFranchiseManager>
            )}
          />
          <Route
            path={ROUTE_FRANCHISE_MANAGER_PAYOUT_DETAIL}
            element={(
              <RequiredFranchiseManager>
                <PayoutReportDetails />
              </RequiredFranchiseManager>
            )}
          /> */}
          <Route
            path={ROUTE_FRANCHISE_MANAGER_PAYOUTS}
            element={(
              <RequiredFranchiseManager>
                <PayoutReports />
              </RequiredFranchiseManager>
            )}
          />
          <Route
            path={ROUTE_FRANCHISE_MANAGER_VANS_PAYOUTS}
            element={(
              <RequiredFranchiseManager>
                <PayoutVansReports />
              </RequiredFranchiseManager>
            )}
          />
          <Route
            path={ROUTE_FRANCHISE_MANAGER_PAYOUT_DETAIL}
            element={(
              <RequiredFranchiseManager>
                <PayoutReportDetails />
              </RequiredFranchiseManager>
            )}
          />
          <Route
            path={ROUTE_FRANCHISE_MANAGER_CUSTOMERS}
            element={(
              <RequiredFranchiseManager>
                <CustomersInformation />
              </RequiredFranchiseManager>
            )}
          />
          {/* <Route
            path={ROUTE_FRANCHISE_MANAGER_FRANCHISES}
            element={(
              <RequiredFranchiseManager>
                <FranchisesList />
              </RequiredFranchiseManager>
            )}
          /> */}
          <Route
            path={ROUTE_FRANCHISE_MANAGER_WORK_ORDER_DETAIL}
            element={(
              <RequiredFranchiseManager>
                <WorkOrderDetails />
              </RequiredFranchiseManager>
            )}
          />
          <Route
            path={ROUTE_FRANCHISE_MANAGER_CUSTOMER_DETAIL}
            element={(
              <RequiredFranchiseManager>
                <FranchiseCustomerManagement />
              </RequiredFranchiseManager>
            )}
          />
          <Route
            path={ROUTE_FRANCHISE_MANAGER_INVOICE_DETAIL}
            element={(
              <RequiredFranchiseManager>
                <InvoiceDetails />
              </RequiredFranchiseManager>
            )}
          />
        </Route>
        <Route
          path="*"
          element={(<NotFound />)}
        />
      </Routes>
    </>
  );
}
