import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import {
  ROUTE_ACCOUNT_ROOT,
  ROUTE_FRANCHISE_MANAGER_WORK_ORDERS,
  ROUTE_ROOT,
} from '../util/constants';

export default function NotFound() {
  const { isAuthenticated, currentUser } = useAppSelector((state) => (
    state.auth
  ));

  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to={ROUTE_ROOT} state={{ from: location }} />;
  }

  if (currentUser.roles?.includes('manager')
  || currentUser.roles?.includes('technician')
  || currentUser.roles?.includes('tester')) {
    return (
      <Navigate
        to={ROUTE_FRANCHISE_MANAGER_WORK_ORDERS}
        state={{ from: location }}
      />
    );
  }

  return <Navigate to={ROUTE_ACCOUNT_ROOT} state={{ from: location }} />;
}
