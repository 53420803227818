import {
  ApolloError, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import cx from 'classnames';
import pick from 'lodash/pick';
import {
  FormEvent, useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import NumberFormat from 'react-number-format';
import { parseISO, isToday, add } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import Button from '../components/Button';
import OrderSummary from '../components/OrderSummary';
import { PasswordInput } from '../components/PasswordInput';
import PreviousSelector, { Selection } from '../components/PreviousSelector';
import TextInput from '../components/TextInput';
import {
  selectAddOns, addAddOn, removeAddOn, AddOn,
} from '../features/addOns/addOnsSlice';
import { login } from '../features/auth/authSlice';
import {
  setBookingSuccess,
  selectPotentialFranchiseId,
  setVanId,
} from '../features/booking/bookingSlice';
import { setPostalCode } from '../features/postalCode/postalCodeSlice';
import {
  Appointment,
  Contact,
  setContact, setDateTime,
  setEmail,
  setName,
  setNewContact,
  setPhone,
} from '../features/serviceLocation/serviceLocationSlice';
import useAuthentication from '../hooks/useAuthentication';
import styles from '../sass/components/Schedule.module.scss';
import {
  ROUTE_BOOK_ADD_ONS,
  ROUTE_BOOK_CONFIRMATION,
  ROUTE_BOOK_SERVICES,
} from '../util/constants';
import { getFieldErrors } from '../util/getFieldErrors';
import { selectServiceTypes } from '../features/serviceTypes/serviceTypesSlice';
import Alert from '../components/Alert';
import { authenticate } from './Login';
import {
  CREATE_WORK_ORDER,
  GET_CONTACTS_BY_USER_ID,
  GET_POSTAL,
  GET_VENDOR_LIST,
  GET_ADD_ON_SURCHARGE_PRICES,
  GET_USER_BY_ID,
  GET_FRANCHISE_VANS,
  GET_TECHNICIAN_VANS,
} from '../util/gql';
import AppointmentDatePicker from '../components/AppointmentDatePicker';
import Select from '../components/EditorSelect';
import { Vendor } from '../interfaces/Customer';
import { Van } from '../interfaces/Van';

export default function Schedule() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loggedIn: isLoggedIn, handleLogin } = useAuthentication();
  const [password, setPassword] = useState('');
  const [constraints, setConstraints] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [isGuest, setIsGuest] = useState(false);
  const [isVendor, setIsVendor] = useState(false);
  const [vendor, setVendor] = useState<Vendor | undefined>();
  const [newVendor, setNewVendor] = useState<boolean>(false);
  const [
    isValidAddress,
    setIsValidAddress,
  ] = useState<undefined | boolean>(undefined);

  const {
    selectedAddress,
    selectedContact,
    serviceAddress,
    dateTime,
    contact: {
      email, name, phoneNumber,
    },
  } = useAppSelector((s) => s.serviceLocation);

  const selectedServices = useAppSelector(selectServiceTypes);
  const selectedAddOns = useAppSelector(selectAddOns);
  const potentialFranchiseId = useAppSelector(selectPotentialFranchiseId);

  const currentUser = useAppSelector((
    state,
  ) => state.auth.currentUser);
  const carId = useAppSelector((state) => state.car.id);
  const vehicle = useAppSelector((state) => state.car);
  const customerId = useAppSelector((state) => state.customer.id);
  const userVehicleId = useAppSelector((state) => state.car.userVehicleId);
  const vehicleCustomName = useAppSelector((state) => state.car.name);
  const vin = useAppSelector((state) => state.car.vin);
  const franchiseIdSelect = useAppSelector((state) => state.franchise.id);
  const [vans, setVans] = useState<Van[]>([]);
  const [selectedVanId, setSelectedVanId] = useState<number | null>(null);

  const [createWorkOrder, {
    loading: workOrderLoading,
  }] = useMutation(CREATE_WORK_ORDER);

  const [loadUserInformation,
    { data: userInfo }] = useLazyQuery(GET_USER_BY_ID);

  const isManager = currentUser.roles?.includes('manager');
  const isTechnician = currentUser.roles?.includes('technician');
  const isTester = currentUser.roles?.includes('tester');

  const [getVans] = useLazyQuery(GET_FRANCHISE_VANS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const franchiseVans = data?.getFranchiseVans || [];
      setVans(franchiseVans);
      if (franchiseVans.length === 1) {
        setSelectedVanId(franchiseVans[0].id);
      }
    },
  });

  const [getTechnicianVan] = useLazyQuery(GET_TECHNICIAN_VANS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const technicianVans = data?.getTechnicianVans || [];
      setSelectedVanId(technicianVans[0]?.id);
    },
  });

  useEffect(() => {
    if ((isManager || isTester) && potentialFranchiseId) {
      getVans({ variables: { franchiseId: potentialFranchiseId } });
    } else if (isTechnician && potentialFranchiseId) {
      getTechnicianVan({ variables: { technicianId: currentUser.id } });
    }
  }, [isManager,
    isTechnician,
    isTester,
    potentialFranchiseId,
    getVans,
    getTechnicianVan,
    currentUser.id]);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 1) {
      const userId = customerId || currentUser.id;
      loadUserInformation({ variables: { userId } });
    }
  }, [customerId, currentUser]);

  const {
    data: contactsData,
    loading: contactsLoading,
    error: contactsError,
  } = useQuery(GET_CONTACTS_BY_USER_ID, {
    variables: { userId: customerId || currentUser.id },
    skip: !isLoggedIn,
    fetchPolicy: 'network-only',
  });

  let contacts: Contact[] = useMemo(() => [], []);

  if (contactsData
    && (((isManager
    || isTechnician
    || isTester) && customerId)
    || (!isManager
    && !isTechnician
    && !isTester))) {
    contacts = contactsData.getContactsByUserId
      .map((c: Contact) => pick(c, ['name', 'email', 'phoneNumber']));
    if (selectedContact === 'unset' && contacts.length > 0) {
      dispatch(setContact([0, contacts[0]]));
    }
  }

  const contactStrings = contacts
    .map((c) => `${c.name}: ${c.phoneNumber} | ${c.email}`);

  useQuery(GET_POSTAL, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { isInService, code } = data.getPostal;
      if (isInService) {
        dispatch(setPostalCode(code));
      }
      setIsValidAddress(isInService);
    },
    variables: {
      code: serviceAddress?.postalCode,
    },
    skip: !serviceAddress?.postalCode,
  });

  useEffect(() => {
    if (serviceAddress?.postalCode) {
      dispatch(setPostalCode(serviceAddress?.postalCode));
    }
  }, [serviceAddress?.postalCode, dispatch]);

  const [
    getVendorList, {
      data: vendorList,
    },
  ] = useLazyQuery(GET_VENDOR_LIST);

  useEffect(() => {
    if (isVendor) {
      getVendorList();
    }
  }, [getVendorList, isVendor]);

  const handleNextClick = async () => {
    setConstraints([]);
    setErrorCode('');
    setErrorMessage('');
    try {
      const { data } = await createWorkOrder({
        variables: {
          date: parseISO(dateTime?.date as string),
          vehicleId: carId,
          // eslint-disable-next-line max-len
          ...(vehicleCustomName && vehicleCustomName.trim() !== '' && { vehicleCustomName }),
          ...(vin && vin.trim() !== '' && { vin }),
          customerId,
          userVehicleId,
          franchiseIdSelect,
          potentialFranchiseId,
          vanId: selectedVanId,
          contactInput: { name, email, phoneNumber },
          addressInput: serviceAddress,
          lineItems: selectedServices.concat(selectedAddOns).map((service) => (
            {
              id: service.id,
              quantity: 1,
              value: Math.floor(service.value * 100),
            })),
          ...((!isLoggedIn
            || isManager
            || isTechnician
            || isTester)
          && !customerId
          && !isGuest && { password }),
          ...(isVendor && { vendorListInput: vendor }),
        },
      });
      if (!isLoggedIn && !isGuest) {
        const onLoginSuccess = (token: string) => {
          dispatch(login({ email, token }));
        };
        authenticate(
          { email, password },
          onLoginSuccess,
          (message: string) => setErrorMessage(message),
          (message: any) => setConstraints(message),
        );
      }
      dispatch(setVanId(data.createWorkOrder.vanId));
      dispatch(setBookingSuccess(true));
      navigate(ROUTE_BOOK_CONFIRMATION);
    } catch (ex) {
      if (ex instanceof ApolloError) {
        const {
          message,
          statusCode,
          error,
        } = ex.graphQLErrors[0].extensions.response;
        if (statusCode === 422) {
          setConstraints(message);
        }
        if (statusCode === 400) {
          setErrorMessage(message);
          setErrorCode(error);
        }
      }
    }
  };
  const vendorId = userInfo?.getUserById?.vendorId || null;

  const { data } = useQuery(GET_ADD_ON_SURCHARGE_PRICES, {
    variables: { id: carId, vendorId },
    fetchPolicy: 'cache-first',
  });

  const handleSetDateTime = useCallback((date: Appointment) => {
    dispatch(setDateTime(date));
    if (data) {
      const shopFee = data.getAddOnSurchargePrices.find(
        (addOn: AddOn) => addOn.name === 'Shop Surcharge',
      );
      const environmentFee = data.getAddOnSurchargePrices.find(
        (addOn: AddOn) => addOn.name === 'Environmental Handling Surcharge',
      );
      const alreadyAddedShopFee = shopFee && selectedAddOns.some(
        (addOn) => addOn.id === shopFee.id,
      );
      const alreadyAddedEnvironmentFee = selectedAddOns.some(
        (addOn) => addOn.id === environmentFee.id,
      );
      if (shopFee && !alreadyAddedShopFee) {
        dispatch(addAddOn(shopFee));
      } else if (alreadyAddedShopFee && !shopFee) {
        dispatch(removeAddOn(shopFee.id));
      }

      if (environmentFee && !alreadyAddedEnvironmentFee) {
        dispatch(addAddOn(environmentFee));
      } else if (alreadyAddedEnvironmentFee && !environmentFee) {
        dispatch(removeAddOn(environmentFee.id));
      }
    }
    if (data && userInfo?.getUserById?.vendorId) {
      if (userInfo?.getUserById?.vendorId === 1) {
        const amzMainService = data.getAddOnSurchargePrices.find(
          (addOn: AddOn) => addOn.name
          === 'AMZ Two Person Requirement Main Service',
        );
        const amzService = data.getAddOnSurchargePrices.find(
          (addOn: AddOn) => addOn.name === 'AMZ Two Person Requirement',
        );
        const uBolt = data.getAddOnSurchargePrices.find(
          (addOn: AddOn) => addOn.name === 'U-Bolt Retorque',
        );

        if (
          !selectedServices.some((service) => service.id === 2)
          && selectedAddOns.some((addOn) => addOn.id === amzMainService?.id)
        ) {
          dispatch(removeAddOn(amzMainService.id));
        }

        if (
          selectedServices.some((service) => service.id === 2)
          && selectedAddOns.some((addOn) => addOn.id === amzService?.id)
          && !selectedAddOns.some((addOn) => addOn.id === amzMainService?.id)
        ) {
          dispatch(removeAddOn(amzService.id));
        }

        // Check if AMZ Main Service should be added
        if (
          selectedServices.some((service) => service.id === 2)
          && !selectedAddOns.some((addOn) => addOn.id === amzMainService?.id)
        ) {
          dispatch(addAddOn(amzMainService));
        } else if (
          !selectedAddOns.some((addOn) => addOn.id === amzService?.id)
          && !selectedAddOns.some((addOn) => addOn.id === amzMainService?.id)
        ) {
          dispatch(addAddOn(amzService));
        }
        // Check if U-Bolt Retorque should be added
        if (
          selectedServices.some((service) => service.name
          === 'Engine Air Filter Replacement')
          && !selectedAddOns.some((addOn) => addOn.id === uBolt?.id)
        ) {
          dispatch(addAddOn(uBolt));
        } else if (!selectedServices.some((service) => service.name
        === 'Engine Air Filter Replacement')
          && selectedAddOns.some((addOn) => addOn.id === uBolt?.id)) {
          dispatch(removeAddOn(uBolt.id));
        }
      }
    }
    if (data && !userInfo?.getUserById?.vendorId) {
      const sameDaySurcharge = data.getAddOnSurchargePrices.find(
        (addOn: AddOn) => addOn.name === 'Same-Day Service Surcharge',
      );
      const nextDaySurcharge = data.getAddOnSurchargePrices.find(
        (addOn: AddOn) => addOn.name === 'Next-Day Service Surcharge',
      );
      const alreadyAddedSameDay = sameDaySurcharge && selectedAddOns.some(
        (addOn) => addOn.id === sameDaySurcharge.id,
      );
      const alreadyAddedNextDay = nextDaySurcharge && selectedAddOns.some(
        (addOn) => addOn.id === nextDaySurcharge.id,
      );
      const selectedDate = parseISO(date.date);
      const isNextDay = isToday(add(selectedDate, { days: -1 }));

      if (isToday(selectedDate) && !alreadyAddedSameDay) {
        dispatch(addAddOn(sameDaySurcharge));
      } else if (!isToday(selectedDate) && alreadyAddedSameDay) {
        dispatch(removeAddOn(sameDaySurcharge.id));
      }

      if (isNextDay && !alreadyAddedNextDay) {
        dispatch(addAddOn(nextDaySurcharge));
      } else if (!isNextDay && alreadyAddedNextDay) {
        dispatch(removeAddOn(nextDaySurcharge.id));
      }
    } else {
      console.log('Surcharge data is still loading or not available.');
    }
  }, [dispatch, data, selectedAddOns]);

  useEffect(() => {
    if (data && dateTime) {
      handleSetDateTime(dateTime);
    }
  }, [data, dateTime, handleSetDateTime]);

  const hasPasswordOrIsGuestOrIsLoggedIn = !!password || isGuest || isLoggedIn;

  const newContactValid = email && name && phoneNumber;
  const contactValid = (selectedContact !== 'new'
  && selectedContact !== 'unset')
    || newContactValid;
  const addressValid = (selectedAddress !== 'new'
  && selectedAddress !== 'unset')
    || isValidAddress;
  const shouldEnableNext = contactValid
    && addressValid
    && dateTime
    && hasPasswordOrIsGuestOrIsLoggedIn;

  const onChangeContactSelector = useCallback((s: Selection) => {
    if (s === 'new' || s === 'unset') {
      dispatch(setNewContact());
    } else {
      dispatch(setContact([s, contacts[s]]));
    }
  }, [dispatch, contacts]);

  const handleChangeVendor = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const vendorSelect = vendorList.getVendors.find(
      (v: Vendor) => v.name === event.target.value,
    );
    setVendor(() => ({
      vendorId: vendorSelect.id,
      name: vendorSelect.name,
      remittance: vendorSelect.remittance,
      isRemittanceOnSubTotal: vendorSelect.isRemittanceOnSubTotal,
    }));
  };

  // eslint-disable-next-line max-len
  const handleRemittanceChange = (event: FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    setVendor((prevVendor) => ({
      ...prevVendor,
      remittance: Number(target.value),
    } as Vendor));
  };

  // eslint-disable-next-line max-len
  const handleNewVendorChange = (event: FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    setVendor((prevVendor) => ({
      ...prevVendor,
      name: target.value,
    } as Vendor));
  };

  // eslint-disable-next-line max-len
  const handleIsRemittanceOnSubTotalChange = (event: FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    setVendor((prevVendor) => ({
      ...prevVendor,
      isRemittanceOnSubTotal: target.checked,
    } as Vendor));
  };

  return (
    <div className={styles.schedule}>
      <div className={styles.header}>
        <h1 className={styles.headerDesktop}>Schedule And Book</h1>
        <h4 className={styles.headerMobile}>Schedule And Book</h4>
        <Button
          variant="secondary"
          onClick={handleLogin}
        >
          {isLoggedIn ? 'Log Out' : 'Log In'}
        </Button>
      </div>
      <div className={cx(styles.body, 'container--fluid')}>
        <div className={cx(styles.bodyLeft, 'container__col-xs-8')}>
          <div className={styles.serviceLocationForm}>
            <form>
              <div className={styles.formHeaderContainer}>
                <div className={styles.formHeader}>
                  <h4>Schedule Your Service</h4>
                  <p>Provide a time for the appointment.</p>
                </div>

                <div className={styles.bookingLegend}>
                  <h4>Booking Slot Availability:</h4>
                  <div className={styles.legendItem}>
                    <span className={`${styles.colorBox}`} />
                    { isManager
                      ? 'No bookings scheduled'
                      : 'Available for direct booking' }
                  </div>
                  <div className={styles.legendItem}>
                    <span className={`${styles.colorBox} ${styles.blue}`} />
                    { isManager
                      ? 'A booking already exists for this slot'
                      : 'Request a booking for this slot' }
                  </div>
                </div>
              </div>

              <div className={cx(styles.formSection, styles.bookingContainer)}>
                <div className={styles.datePickerContainer}>
                  <AppointmentDatePicker
                    onSelect={handleSetDateTime}
                    value={dateTime}
                    timeNoLongerAvailable={
                      errorCode === 'TIME_SLOT_UNAVAILABLE'
                    }
                  />
                </div>
              </div>

              {(isManager || isTester) && vans.length > 1 && (
              <div className={styles.formHeader}>
                <h4>Van Selection</h4>
                <p>Select a van for this booking.</p>
                <PreviousSelector
                  data={
                    vans.map((van) => `${van.name}`)
                  }
                  name="van"
                  selected={
                    vans.findIndex((van) => van.id === selectedVanId)
                  }
                  onChange={(selection) => {
                    if (typeof selection === 'number' && vans[selection]) {
                      setSelectedVanId(vans[selection].id);
                    }
                  }}
                  childClassName={styles.formSubsection}
                  hideNewOption
                  newLabel="Select a new van"
                  loading={false}
                  error={false}
                >
                  <p>
                    Please ensure you select the correct van for this booking.
                  </p>
                </PreviousSelector>
              </div>

              )}

              <div className={styles.formHeader}>
                <h4>Contact Information</h4>
                <p>
                  Select a primary contact for this booking.
                  We will never share contact information with anyone else.
                </p>
                <PreviousSelector
                  data={contactStrings}
                  newLabel="Add another contact"
                  loading={contactsLoading}
                  error={!!contactsError}
                  name="contact"
                  selected={selectedContact}
                  onChange={onChangeContactSelector}
                  childClassName={styles.formSubsection}
                >

                  <TextInput
                    id="name"
                    value={name}
                    title="Name"
                    className={styles.input}
                    onChange={
                      (event: FormEvent<HTMLInputElement>) => (
                        dispatch(setName(event.currentTarget.value)))
                    }
                  />

                  <NumberFormat
                    id="phone"
                    title="Phone Number"
                    className={styles.input}
                    errorMessage={
                      getFieldErrors(constraints, ['contact', 'phoneNumber'])
                    }
                    value={phoneNumber}
                    onChange={
                      (event: FormEvent<HTMLInputElement>) => (
                        dispatch(setPhone(event.currentTarget.value)))
                    }
                    format="(###) ###-####"
                    mask="_"
                    allowEmptyFormatting
                    customInput={TextInput}
                  />
                  <div className={styles.passwordBox}>
                    <TextInput
                      id="email"
                      value={email}
                      title="Email"
                      className={styles.input}
                      errorMessage={
                      getFieldErrors(constraints, ['contact', 'email'])
                    }
                      onChange={
                      (event: FormEvent<HTMLInputElement>) => (
                        dispatch(setEmail(event.currentTarget.value)))
                    }
                      hint="The customer will receive a
                          confirmation at this email address."
                    />
                    {(isManager
                    || isTechnician
                    || isTester)
                    && !customerId && (
                      <label className={styles.bookAsVendor} htmlFor="vendor">
                        <input
                          id="vendor"
                          type="checkbox"
                          checked={isVendor}
                          onChange={() => setIsVendor(!isVendor)}
                        />
                        <span>Book appointment for vendor</span>
                      </label>
                    )}
                  </div>

                  {(!isLoggedIn
                  || isManager
                  || isTechnician
                  || isTester)
                  && !customerId && (
                    <div className={styles.passwordBox}>
                      <PasswordInput
                        value={password}
                        placeholder="Password"
                        className={styles.input}
                        onChange={
                          (event) => setPassword(event.currentTarget.value)
                        }
                        title="Password"
                        errorMessage={getFieldErrors(constraints, ['password'])}
                        hint={'Log in with this password '
                          + 'anytime to manage your bookings.'}
                        disabled={isGuest}
                      />
                      <label className={styles.bookAsGuest} htmlFor="guest">
                        <input
                          id="guest"
                          type="checkbox"
                          checked={isGuest}
                          onChange={() => setIsGuest(!isGuest)}
                        />
                        <span>Book appointment as guest</span>
                      </label>
                    </div>
                  )}
                  {isVendor && (
                  <>
                    { vendorList && (
                    <div className={styles.containerNewVendor}>
                      <br />
                      <p>
                        Select a fleet or add a new one if the customer`s
                        fleet is not listed in the dropdown menu.
                      </p>
                    </div>
                    )}
                    { vendorList && !newVendor && (
                    <div className={styles.container}>
                      <Select
                        label="Select fleet from list"
                        loading={false}
                        id="vendor-input"
                        placeholder="Select Fleet"
                        value={vendor?.name?.toString() || ''}
                        onChange={handleChangeVendor}
                      >
                        {vendorList.getVendors.map((vendorSelect: Vendor) => (
                          <option
                            key={vendorSelect.vendorId}
                            value={vendorSelect?.vendorId?.toString()}
                          >
                            {vendorSelect?.name}
                          </option>
                        ))}
                      </Select>
                      <TextInput
                        className={styles.individualItem}
                        title="remittance fee %"
                        placeholder="fee"
                        type="number"
                        value={vendor?.remittance
                          ? vendor.remittance.toString() : ''}
                        min={0}
                      />
                      <label
                        className={styles.bookAsGuest}
                        htmlFor="isRemittenceOnSubTotal"
                      >
                        <input
                          id="isRemittenceOnSubTotal"
                          type="checkbox"
                          checked={!!vendor?.isRemittanceOnSubTotal}
                          onChange={handleIsRemittanceOnSubTotalChange}
                          disabled
                        />
                        <span>Remittance is calculated based on sub total</span>
                      </label>
                    </div>
                    )}
                    { vendorList && (
                    <div className={styles.containerNewVendor}>
                      <br />
                      <Button
                        onClick={() => {
                          setVendor(({
                            vendorId: 0,
                            name: '',
                            remittance: 0,
                            isRemittanceOnSubTotal: true,
                          }));
                          setNewVendor((prevState) => !prevState);
                        }}
                        variant="primary"
                        size="small"
                        className={styles.addButton}
                      >
                        {newVendor ? 'Select vendor from list' : 'New Vendor'}
                      </Button>
                    </div>
                    )}

                    {newVendor && (
                    <div className={styles.container}>
                      <TextInput
                        className={styles.individualItemName}
                        title="Fleet Name"
                        placeholder="Enter fleet name"
                        type="text"
                        value={vendor?.name}
                        onChange={handleNewVendorChange}
                      />

                      <TextInput
                        className={styles.individualItem}
                        title="Remittance Fee %"
                        placeholder="fee"
                        type="number"
                        value={vendor?.remittance?.toString()}
                        min={0}
                        name="remittance"
                        onChange={handleRemittanceChange}
                      />
                      <label className={styles.bookAsGuest} htmlFor="isRemittenceOnSubTotal">
                        <input
                          id="isRemittenceOnSubTotal"
                          type="checkbox"
                          checked={vendor?.isRemittanceOnSubTotal || false}
                          onChange={handleIsRemittanceOnSubTotalChange}
                        />
                        <span>Remittance calculates based on Sub Total</span>
                      </label>
                    </div>
                    )}
                  </>
                  )}
                </PreviousSelector>

              </div>

              <Alert
                message={errorCode === 'TIME_SLOT_UNAVAILABLE'
                  ? ''
                  : errorMessage}
                type="error"
              />
            </form>
          </div>
        </div>
        <div className={cx(
          styles.bodyRight,
          'container__col-xs-4',
        )}
        >
          <OrderSummary />
        </div>
      </div>
      <div className={styles.footer}>
        {(userInfo?.getUserById?.vendorId === 1) ? (
          <Button
            onClick={() => navigate(ROUTE_BOOK_SERVICES)}
            variant="tertiary"
            className={styles.selectButton}
          >
            Back
          </Button>
        ) : (
          <Button
            onClick={() => navigate(ROUTE_BOOK_ADD_ONS)}
            variant="tertiary"
            className={styles.selectButton}
          >
            Back
          </Button>
        )}

        <Button
          variant="primary"
          className={styles.selectButton}
          onClick={handleNextClick}
          inactive={!shouldEnableNext}
          loading={workOrderLoading}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
