import { useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import styles
  from '../sass/components/Payout.module.scss';
import {
  GET_PAYOUT_REPORTS_FRANCHISE_MANAGER_RANGE,
} from '../util/gql';
import { useAppSelector } from '../app/hooks';
import useAuthentication from '../hooks/useAuthentication';
import { GroupedPayoutReports } from '../interfaces/PayoutReport';
import EmptyList from './EmptyList';
import PayoutReportList from './PayoutReportList';
import Button from './Button';
import { dateString, weekdayDateString } from '../util/formatDate';

export default function PayoutReports() {
  const [payoutReports, setPayoutReports] = useState<GroupedPayoutReports>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState<number>(0);
  const [currentCount, setCurrentCount] = useState<number>(0);

  const { loggedIn: isLoggedIn } = useAuthentication();
  const franchiseIdSelect = useAppSelector((state) => state.franchise.id);
  const { franchiseId } = useAppSelector((state) => state.auth.currentUser);

  const [
    getPayoutReports, { loading },
  ] = useLazyQuery(GET_PAYOUT_REPORTS_FRANCHISE_MANAGER_RANGE, {
    onCompleted: (data) => {
      try {
        const payoutReportList = data
          ?.getPayoutStatementsFranchiseManagerRange.groupedDateRanges || [];
        setCurrentCount(currentCount + payoutReportList.length);
        setTotal(data?.getPayoutStatementsFranchiseManagerRange.total || 0);

        const groupedByMonthYear = payoutReportList.reduce(
          (acc: any, current: any) => {
            const endDate = new Date(current.endDate);
            const monthYear = `${endDate.toLocaleString(
              'default', { month: 'long' },
            )} ${endDate.getFullYear()}`;
            acc[monthYear] = acc[monthYear] || [];
            acc[monthYear].push(current);
            return acc;
          }, {},
        );

        setPayoutReports((prev) => ({ ...prev, ...groupedByMonthYear }));
      } catch (error) {
        console.error('Error processing payout reports:', error);
      }
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setPayoutReports({});
    setCurrentPage(1);
    setCurrentCount(0);
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      getPayoutReports({
        variables: {
          franchiseId: franchiseIdSelect || franchiseId,
          pageNumber: currentPage,
        },
      });
    }
  }, [isLoggedIn,
    getPayoutReports,
    currentPage,
    franchiseIdSelect,
    franchiseId]);

  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1);
  };

  const content = Object.keys(payoutReports).length > 0 ? (
    <>
      <PayoutReportList payoutReports={payoutReports} />
      {currentCount < total && (
        <div
          className={styles.loadMoreButtonContainer}
        >
          <Button
            variant="secondary"
            onClick={handleLoadMore}
            className={styles.loadMoreButton}
          >
            Load more
          </Button>
        </div>
      )}

    </>
  ) : (
    <EmptyList msg="No Payout Statements exist yet" />
  );

  return (
    <>
      <div className={styles.customerInformation}>
        <h4 className={styles.paymentHeader}>
          Payout Statements
        </h4>
      </div>
      <div className={styles.completedWorkOrders}>
        {loading ? <p className={styles.loading}>Loading...</p> : content}
      </div>
    </>
  );
}
